import Swiper from 'swiper'
import { Autoplay, EffectCoverflow, EffectCreative, EffectFade, Navigation } from 'swiper/modules'

export default function swiperGlobal() {
	const swiperIntro = new Swiper('.swiper-depoimentos', {
		slidesPerView: 1,
		breakpoints: {
			540: {},
			940: {
				slidesPerView: 3,
			},
		},
		speed: 1000,
		loop: true,
		modules: [Navigation, Autoplay],
		// autoHeight: true,
		autoplay: {
			delay: 3000,
		},
		navigation: {
			nextEl: '.quotes .w-slider-arrow-right',
			prevEl: '.quotes .w-slider-arrow-left',
		},
	})
	new Swiper('.swiper-carrossel-logos', {
		slidesPerView: 'auto',
		modules: [Autoplay],
		speed: 3000,

		noSwipingClass: '.logo-image',
		loop: true,
		autoplay: {
			pauseOnMouseEnter: false,
			delay: 0,
		},
	})
}
