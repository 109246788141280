import { find } from '../utils/dom'

export default function (props = {}) {
	// Defaults
	const opts = {
		selector: '[js-header]',
		fixedOnScrool: true,
		animateOnFixed: false,
		background: true,
		topReduce: 40,
		translate: true,
		...props,
	}

	const header = find(opts.selector)
	let lastScrollTop = 0
	let ticking = false
	function onScroll() {
		window.addEventListener('scroll', (e) => {
			const point = window.scrollY > opts.topReduce

			if (document.body.classList.contains('haveLoader')) {
				if (!document.body.classList.contains('loaded')) return
			}
			const currentScroll = window.pageYOffset || document.documentElement.scrollTop

			if (!ticking) {
				window.requestAnimationFrame(() => {
					if (currentScroll > lastScrollTop) {
						translate(false)
					} else if (currentScroll < lastScrollTop) {
						translate(true)
					}
					lastScrollTop = currentScroll <= 0 ? 0 : currentScroll // Evita valores negativos
					ticking = false
				})
				ticking = true
			}

			if (opts.background) background(point)
			if (opts.fixedOnScrool) fixed(point)
		})
	}

	/*** @param {boolean} value */
	function fixed(value, animate = false) {
		header.setAttribute('js-header-fixed', value)
		// animate && header.setAttribute('js-header-animate', value)
	}
	/*** @param {boolean} value */
	function translate(value) {
		header.setAttribute('js-header-translate', value)
		const translate = document.querySelector(".nav_fixed[js-header-fixed='true']")

		if (!translate) return
		if (!value)
			gsap.set(header, {
				y: -100,
			})
		else
			gsap.to(header, {
				duration: 0.2,
				overwrite: true,
				y: 0,
			})
	}

	/*** @param {boolean} value */
	function background(value) {
		header.setAttribute('js-header-background', value)
	}

	function init() {
		if (header) {
			onScroll()
		}
		return this
	}

	return {
		init,
		header,
		opts,
		fixed,
		reduce: background,
	}
}
