import UseMouseOrKeyboard from './modules/UseMouseOrKeyboard'
import formErros from './modules/formErros'
import logs from './utils/logs'
import dataParallax from './animation/dataParallax'
import { initLenis, animationSetup } from './animation/animationSetup'
import textLineReveal from './animation/textLineReveal'
import { animateFromInit } from './animation/animateFrom'
import Home from './pages/Home'
import header from './modules/header'
import Cliente from './pages/Cliente'
import { emitter } from './modules/emitter'
import menuMobile from './modules/menuMobile'
import contatoForm from './pages/Contato'
import axios from 'axios'
import EditorJs from './modules/editor'
import swiperGlobal from './modules/swiperGlobal'
import Clientes from './pages/Clientes'
import Servicos from './pages/Servicos'
import { find } from './utils/dom'
import LGPD from './pages/lgpd'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.headers.post['X-CSRFTOKEN'] = window.CSRF_TOKEN // Use the token injected in the template

const haveLoader = document.body.classList.contains('haveLoader')
if (haveLoader) emitter.on('loader:complete', initLenis)
else initLenis()

header().init()
animationSetup()
textLineReveal().init()
animateFromInit()
UseMouseOrKeyboard()
formErros()
logs()
dataParallax()
menuMobile()
EditorJs()

document.addEventListener('DOMContentLoaded', () => {
	find('body').style.opacity = 1
	console.log('DOMContentLoaded zz')
	Home().init()
	Cliente().init()
	Clientes().init()
	contatoForm() 
	LGPD()
	Servicos().init()

	swiperGlobal()
})

// Pages
