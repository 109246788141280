import gsap from 'gsap'
import Swiper from 'swiper'
import { Autoplay, EffectCoverflow, EffectCreative, EffectFade, Pagination } from 'swiper/modules'
import { find } from 'utils/dom'
import { findAll, height } from '../utils/dom'
import { eases } from '../animation/animationSetup'
import { size } from '../utils/global'

export default function () {
	const Page = find("[js-page='Home']")
	if (!Page) return { init: () => null }
	console.log('home')
	function initSwipers() {
		const swiperIntroText = new Swiper('.swiper-intro-text', {
			slidesPerView: 1,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			modules: [EffectFade],
			on: {
				transitionStart: function (swiper) {
					const text = find('[data-text-split]', swiper.slides[swiper.activeIndex])
					const btn = find('[data-anima ]', swiper.slides[swiper.activeIndex])
					text.tl.progress(0).restart()
					btn.tl.progress(0).restart()
				},
			},
		})

		const swiperIntro = new Swiper('.swiper-intro', {
			slidesPerView: 1,
			effect: 'fade',
			autoplay: {
				delay: 3000,
				disableOnInteraction: true,
			},
			modules: [EffectFade, Autoplay],
			loop: true,
			on: {
				transitionStart: function (swiper) {
					swiperIntroText.slideTo(swiper?.realIndex)
				},
			},
		})
		const swiperComunicacao = new Swiper('.swiper-comunicacao', {
			effect: 'coverflow',
			loop: true,
			speed: 800,
			spaceBetween: 16,
			slidesPerView: 1.1,

			modules: [EffectCoverflow, Autoplay,Pagination],

			pagination:{
				el: '.swiper-pagination',
				clickable: true,
			},
			coverflowEffect: {
				rotate: 50,
				stretch: 0,
				depth: 100,
				modifier: 1,
				slideShadows: false,
			},
			autoplay: {
				delay: 3000,
				disableOnInteraction: true,
			},
			breakpoints: {
				1024: {
					spaceBetween: 0,
					// spaceBetween: 32,
				},
			},
		})
	}

	function processoSection() {
		const $ = {
			active: 1,
			swiperSelector: '.swiper-processo',
			itemSelector: '.Processos .item',
			descricaoSelector: '.descricao',
			textWrapperSelector: '.text-wrapper',
		}

		const swiperProcesso = new Swiper($.swiperSelector, {
			modules: [EffectFade, Autoplay],
			grabCursor: false,
			effect: 'fade',
			speed: 900,
			autoplay: {
				delay: 2000,
				disableOnInteraction: true,
			},
			breakpoints: {
				1024: {},
			},
			on: {
				realIndexChange: (swiper) => {
					swiper.realIndex
					const item = document.querySelector(`.item-processo[data-value="${swiper.realIndex + 1}"]`)
					removeActive()
					item.classList.add('active')
					// console.log(item)
				},
			},
		})

		function removeActive() {
			findAll($.itemSelector).forEach((item) => {
				item.classList.remove('active')
				const descricao = item.querySelector($.descricaoSelector)
				const descricaoHeight = height(descricao, 0)
				gsap.to(find($.textWrapperSelector, item), {
					y: descricaoHeight,
				})
			})
		}

		function initialState() {
			findAll($.itemSelector).forEach((item, i) => {
				const descricao = item.querySelector($.descricaoSelector)
				const descricaoHeight = height(descricao)
				item.setAttribute('data-height', descricaoHeight)
				item.setAttribute('data-value', i + 1)

				gsap.to(find($.textWrapperSelector, item), {
					y: descricaoHeight,
				})
			})
		}

		function addEvents() {
			findAll($.itemSelector).forEach((item) => {
				item.addEventListener('mouseenter', () => {
					removeActive()
					item.classList.add('active')
					swiperProcesso.slideTo(item.getAttribute('data-value') - 1)
				})
			})
		}

		function onMobile() {
			if (size.md) {
				const processos = find('.Processos')
				processos.style.setProperty('--section-height', `${height(processos)}px`)
			}
		}

		function processoAnimation() {
			const section = find('.section-logo-wrapper')
			const TL = gsap
				.timeline({
					scrollTrigger: {
						trigger: section,
						start: 'bottom bottom',
						pin: true,
						markers: false,
						scrub: 1,
						end: '+=1000', // end after scrolling 500px beyond the start
						anticipatePin: true,
					},
				})
				// .to('.Logos .img-logo', { filter: 'grayscale(0)', duration: 0.1 })
				.to('.Logos', { opacity: 0, duration: 0.1, ease: eases.default }, 'anim1')
				.to('.home-logos-bg-white', { opacity: 1, duration: 0.1, ease: eases.default }, 'anim1')
				.fromTo('.Processos', { yPercent: 100, ease: 'none' }, { yPercent: 0 }, '<')
		}

		function init() {
			initialState()
			addEvents()
			onMobile()
			processoAnimation()
		}

		return {
			init,
		}
	}

	function servicosFloatImage() {
		const serviceSection = document.querySelector('.home-service_list')

		const floatingImage = find('.image-float', serviceSection)
		if (!floatingImage) return
		floatingImage.classList.add('active')

		const xTo = gsap.quickSetter(floatingImage, 'x', 'px')
		const yTo = gsap.quickSetter(floatingImage, 'y', 'px')

		let animationFrame // Variável para controlar o requestAnimationFrame

		function updateImagePosition(event, item, { xTo, yTo, dataActive, floatingImage }) {
			// Calcula a posição do mouse relativa ao container
			const rect = item.getBoundingClientRect()
			const mouseX = event.clientX - rect.left
			const mouseY = event.clientY - rect.top - 170

			// Define os deslocamentos (ajuste conforme necessário)
			const offsetX = 20 // deslocamento à direita do mouse
			const offsetY = -50 // deslocamento acima do mouse

			const img = floatingImage.querySelector(`[data-item='${dataActive}']`)
			const imgs = document.querySelectorAll('img[data-item]')

			imgs.forEach((i) => i.classList.remove('active'))
			img && img.classList.add('active')

			// Anima a imagem para a posição do mouse com os deslocamentos aplicados
			xTo(mouseX + offsetX)
			yTo(mouseY + offsetY)
		}

		serviceSection.addEventListener('mousemove', (event) => {
			const dataActive =
				event.target.getAttribute('data-item') || event.target.closest('[data-item]')?.getAttribute('data-item')

			// Usa requestAnimationFrame para otimizar a chamada de updateImagePosition
			cancelAnimationFrame(animationFrame)
			animationFrame = requestAnimationFrame(() => {
				updateImagePosition(event, serviceSection, { xTo, yTo, dataActive, floatingImage })
			})
		})

		serviceSection.addEventListener('mouseenter', (event) => {
			gsap.to(floatingImage, {
				scale: 1,
				skewX: 0,
				duration: 0.3,
				transformOrigin: 'bottom left',
				overwrite: true,
				opacity: 1,
			})
		})

		serviceSection.addEventListener('mouseleave', (event) => {
			floatingImage.classList.remove('active')

			gsap.to(floatingImage, {
				scale: 0,
				skewX: -20,
				transformOrigin: 'bottom left',
				duration: 0.2,
				opacity: 0,
			})
		})
	}


	function init() {
		initSwipers()
		servicosFloatImage()
		processoSection().init()
	}

	return {
		init,
	}
}
