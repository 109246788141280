import gsap from 'gsap'
import Swiper from 'swiper'
import { EffectCreative, EffectFade, Autoplay } from 'swiper/modules'
import { find } from 'utils/dom'
import { findAll, height } from '../utils/dom'

export default function () {
	const Page = find("[js-page='Cliente']")
	if (!Page) return { init: () => null }

	function initSwipers() {
		new Swiper('.swiper-logos', {
			modules: [Autoplay],
			autoplay: {
				delay: 200,
			},
		})

		const swiperComentarios = new Swiper('.swiper-comentarios', {
			slidesPerView: 'auto',
		})
		document.querySelector('.w-slider-arrow-left').addEventListener('click', () => {
			swiperComentarios.slidePrev()
		})
		document.querySelector('.w-slider-arrow-right').addEventListener('click', () => {
			swiperComentarios.slideNext()
		})
	}

	function init() {
		initSwipers()
	}

	return {
		init,
	}
}
