import axios from 'axios'
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'

export default function LGPD() {
	const contatoFormSubmit = document.querySelector('#wf-form-Contact-Form-2')

	contatoFormSubmit &&
		contatoFormSubmit.addEventListener('submit', function (event) {
			event.preventDefault()
			disabledBtn()

			///const fileInput = document.getElementById('imageFileForm');

			//console.log("arte_evento", fileInput)

			///const arte_evento = fileInput.files[0];
			///console.log("arte_evento", arte_evento);

			// const form_data = new FormData()
			// form_data.append('nome', document.querySelector('#wf-form-Contact-Form input[name=nome]').value)
			// form_data.append('email', document.querySelector('#wf-form-Contact-Form input[name=email]').value)
			// form_data.append('telefone', document.querySelector('#wf-form-Contact-Form input[name=telefone]').value)
			// form_data.append('mensagem', document.querySelector('#wf-form-Contact-Form textarea[name=mensagem]').value)

			const nome = document.querySelector('#wf-form-Contact-Form-2 input[name=nome]').value
			const email = document.querySelector('#wf-form-Contact-Form-2 input[name=email]').value
			const celular = document.querySelector('#wf-form-Contact-Form-2 input[name=celular]').value
			const assunto = document.querySelector('#wf-form-Contact-Form-2 select[name=assunto]').value
			const mensagem = document.querySelector('#wf-form-Contact-Form-2 textarea[name=mensagem]').value

			///form_data.append('arte_evento', document.querySelector('#form-contato input[name=imageFileForm]').files[0])

			const url = '/canal-de-comunicacao/'
			axios
				.post(url, {
					nome,
					email,
					celular,
					assunto,
					mensagem,
				})
				.then((response) => showMessage(response))
				.catch((response) => showMessage(response))
		})

		// Toastify({
		// 	text: 'opa',
		// 	duration: 113000,
		// 	newWindow: true,
		// 	close: false,
		// 	gravity: 'top', // `top` or `bottom`
		// 	position: 'center', // `left`, `center` or `right`
		// 	stopOnFocus: true, // Prevents dismissing of toast on hover
		// 	style: {
		// 		background: '#51C878',
		// 		color: '#161616',
		// 	}, // Callback after click
		// }).showToast()

	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		Toastify({
			text: message,
			duration: 3000,
			newWindow: true,
			close: false,
			gravity: 'top', // `top` or `bottom`
			position: 'center', // `left`, `center` or `right`
			stopOnFocus: true, // Prevents dismissing of toast on hover
			style: {
				// background: 'linear-gradient(to right, #00b09b, #96c93d)',
				background: '#51C878',
				color: '#161616',
			}, // Callback after click
		}).showToast()

		// GLOBAL.showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector('#wf-form-Contact-Form-2 input[type=submit]')

	const btnConfirm = document.querySelector('.button-custom-confirmar')


	function checkBtn(){
		console.log('opa ?')
		const sim  = document.querySelector('#aceite-sim').checked
		const nao  = document.querySelector('#aceite-nao').checked 
	
		if (!sim && !nao) {
			btnConfirm.disabled = true
			btnConfirm.classList.add('disabled')
		}else{
			btnConfirm.disabled = false
			btnConfirm.classList.remove('disabled')
		}
	}
	checkBtn()
	btnConfirm?.addEventListener('click', function (event) {
		let msg = ''
		const sim  = document.querySelector('#aceite-sim').checked
		const nao  = document.querySelector('#aceite-nao').checked 
		if(sim){
			msg= 'Obrigado por aceitar nossa Política de Privacidade! Agora você pode aproveitar todos os recursos do site com segurança.'
		}
		else if (nao){
			msg = 'Você recusou nossa Política de Privacidade. Alguns recursos do site podem estar limitados. Deseja reconsiderar? '
		}
			Toastify({
				text: msg,
				duration: 3000,
				newWindow: true,
				close: false,
				gravity: 'top', // `top` or `bottom`
				position: 'center', // `left`, `center` or `right`
				stopOnFocus: true, // Prevents dismissing of toast on hover
				style: {
					// background: 'linear-gradient(to right, #00b09b, #96c93d)',
					background: '#51C878',
					color: '#161616',
				}, // Callback after click
			}).showToast()
	})

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.classList.add('disabled')
		btnSubmit.value = 'Por favor, aguarde...'
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.classList.remove('disabled')
		btnSubmit.value = 'Enviar'
	}


		const sim  = document.querySelector('#aceite-sim')
		const nao  = document.querySelector('#aceite-nao')

		
		
			sim.addEventListener('change', checkBtn)
			nao.addEventListener('change', checkBtn)
	
	
}
