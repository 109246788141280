import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from 'lenis'

export const eases = {
	// default: 'expo.out',
	default: '.875,.35,.68,.53',
}

export function initLenis() {
	const lenis = new Lenis()
	window.gsap = gsap
	window.lenis = lenis
	window.ScrollTrigger = ScrollTrigger
	lenis.on('scroll', ScrollTrigger.update)
	gsap.ticker.add((time) => lenis.raf(time * 1000))
	gsap.ticker.lagSmoothing(0)
	ScrollTrigger.refresh()
}

export function animationSetup() {
	gsap.registerPlugin(ScrollTrigger)
}
