import { find } from 'utils/dom'
import { findAll, height } from '../utils/dom'

export default function () {
	const Page = find("[js-page='Servicos']")
	if (!Page) return { init: () => null }

	


	function init() {
		document.querySelectorAll(".service_tab").forEach(tab => {
			tab.querySelector(".service_tab-top").addEventListener("click", function () {
					const isActive = tab.classList.contains("active");
					
					// Fecha todos os outros antes de abrir um novo
					document.querySelectorAll(".service_tab").forEach(item => {
							if (item !== tab) {
									item.classList.remove("active");
									item.querySelector(".tab-bottom").style.maxHeight = "0px";
							}
					});
					
					// Alterna a classe active
					if (!isActive) {
							tab.classList.add("active");
							const content = tab.querySelector(".tab-bottom");
							content.style.maxHeight = content.scrollHeight + "px";
					} else {
							tab.classList.remove("active");
							tab.querySelector(".tab-bottom").style.maxHeight = "0px";
					}
			});
	});
	}

	return {
		init,
	}

}