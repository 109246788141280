import gsap from 'gsap'

export default function () {
	// Seleciona todos os elementos com o atributo data-parallax que ainda não estão animando.
	console.log('parallax')
	document.querySelectorAll('[data-parallax]').forEach((element) => {
		scrollParallax(element)
	})
}

function valid(param) {
	if (param === null || param === undefined) {
		return false
	}
	return param
}

export function scrollParallax(element) {
	element.classList.add('js-running') // Marca o elemento como "em execução".

	// Define valores padrão para as propriedades de animação, baseando-se nos atributos de dados do elemento.
	const translateYFrom = element.dataset.translateYFrom || '0'
	const translateYTo = element.dataset.translateY || '0'
	const translateXFrom = element.dataset.translateXFrom || '0'
	const translateXTo = element.dataset.translateX || '0'
	const rotateFrom = element.dataset.rotateFrom || '0deg'
	const rotateTo = element.dataset.rotateTo || '0deg'
	const scaleFrom = element.dataset.scaleFrom || '1'
	const scaleTo = element.dataset.scale || '1'
	const duration = parseFloat(element.dataset.duration) || 1
	const repeatCount = parseInt(element.dataset.repeat, 10) || 0
	const yoyoEffect = element.dataset.yoyo === 'true'
	let triggerElement = element.dataset.trigger ? document.querySelector(element.dataset.trigger) : element
	const endTriggerElement = element.dataset.endTrigger
		? document.querySelector(element.dataset.endTrigger)
		: triggerElement
	const easing = element.dataset.ease || 'Power0.easeInOut'
	const delay = parseFloat(element.dataset.delay) || 0
	const repeatDelay = parseFloat(element.dataset.repeatDelay) || 0
	const opacityTo = parseFloat(element.dataset.opacity) || 1
	const transformOrigin = element.dataset.transformOrigin || 'center center'
	const opacityFrom = valid(element.dataset.opacityFrom) || 1

	let scrollStart = element.dataset.start || 'top bottom'
	let scrollEnd = element.dataset.end || 'bottom top'
	const showMarkers = element.dataset.parallaxTop ? true : false

	// Ajuste para casos especiais onde o gatilho é o pai ou quando data-parallax-top está presente.
	if (element.dataset.trigger === 'parent') {
		triggerElement = element.parentElement
	} else if (element.dataset.trigger === 'wrapper') {
		triggerElement = element.closest('.wrapper')
	}

	if (element.dataset.parallaxTop) {
		triggerElement = document.querySelector('.wrapper')
		const viewportHeight = window.innerHeight
		scrollStart = element.dataset.start || `${viewportHeight} top`
		scrollEnd = element.dataset.end || `${viewportHeight * 2} top`
	}

	const scrubEffect = +valid(element.dataset.scrub) || 0.5 // Define se a animação será controlada pelo scroll.

	// Configura a timeline de animação com GSAP.
	const from = {
		y: translateYFrom,
		x: translateXFrom,
		rotate: rotateFrom,
		opacity: opacityFrom,
		scale: scaleFrom,
		force3D: true,
	}
	const to = {
		y: translateYTo,
		x: translateXTo,
		rotate: rotateTo,
		scale: scaleTo,
		duration: duration,
		opacity: opacityTo,
		ease: easing,
		transformOrigin,
	}
	const tl = gsap
		.timeline({
			repeat: repeatCount,
			delay: delay,
			repeatDelay: repeatDelay,
			yoyo: yoyoEffect,
			scrollTrigger: {
				trigger: triggerElement,
				endTrigger: endTriggerElement,
				start: scrollStart,
				end: scrollEnd,
				pinSpacing: false,
				markers: false,
				// id: element.classList[0] || '',
				scrub: scrubEffect,
				anticipatePin: true,
				invalidateOnRefresh: true,
				toggleActions: 'play pause play pause',
				onUpdate: (scrollTrigger) => {
					// Função de callback para atualizações durante o scroll (opcional).
				},
			},
		})
		.fromTo(element, from, to)
	element.tl = tl
}
