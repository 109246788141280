import { gsap } from 'gsap'
import { findAll, height } from '../utils/dom'

export function animateFrom(element) {
	if (element.classList.contains('js-running')) return
	element.classList.add('js-running')

	const translateYFrom = element.dataset.translateYFrom || '0'
	const translateYTo = element.dataset.translateY || '0'
	const translateXFrom = element.dataset.translateXFrom || '0'
	const translateXTo = element.dataset.translateX || '0'
	const rotateFrom = element.dataset.rotateFrom || '0deg'
	const rotateTo = element.dataset.rotateTo || '0deg'
	const scaleFrom = element.dataset.scaleFrom || '1'
	const scaleTo = element.dataset.scale || '1'
	const repeatCount = parseInt(element.dataset.repeat, 10) || 0
	const duration = parseFloat(element.dataset.duration) || 1
	let triggerElement = element.dataset.trigger ? document.querySelector(element.dataset.trigger) : element
	const endTriggerElement = element.dataset.endTrigger
		? document.querySelector(element.dataset.endTrigger)
		: triggerElement
	const easing = element.dataset.ease || 'Power0.easeInOut'
	const delay = parseFloat(element.dataset.delay) || 0
	const repeatDelay = parseFloat(element.dataset.repeatDelay) || 0
	const opacityTo = parseFloat(element.dataset.opacity) || 1
	const opacityFrom =
		element.dataset.opacityFrom === null || element.dataset.opacityFrom === undefined ? 1 : element.dataset.opacityFrom
	const scrollStart = element.dataset.start || 'top center'
	const scrollEnd = element.dataset.end || 'bottom top'
	const yoyoEffect = element.dataset.yoyo === 'true'
	const filter = element.dataset.filter || null
	const once = element.dataset.once
	const scrubEffect = false /* !(repeatCount === -1) */ // Define se a animação será controlada pelo scroll.

	if (element.dataset.trigger === 'parent') {
		triggerElement = element.parentElement
	} else if (element.dataset.trigger === 'wrapper') {
		triggerElement = element.closest('.wrapper')
	}

	const from = {
		x: translateXFrom,
		y: translateYFrom,
		rotate: rotateFrom,
		scale: scaleFrom,
		opacity: opacityFrom,
		overwrite: true,
	}
	const to = {
		opacity: opacityTo,
		ease: easing,
		duration: duration,
		x: translateXTo,
		y: translateYTo,
		delay: delay,
		rotate: rotateTo,
		scale: scaleTo,
		filter,

		// onComplete: () => elem.classList.add("animou"),
		// onStart: () => elem.closest('section').classList.add("animate-enter")
	}

	const tl = gsap
		.timeline({
			repeat: repeatCount,
			delay: delay,
			repeatDelay: repeatDelay,
			yoyo: yoyoEffect,
			scrollTrigger: {
				trigger: triggerElement,
				endTrigger: endTriggerElement,
				start: scrollStart,
				end: scrollEnd,
				pinSpacing: false,
				markers: false,
				// id: element.classList[0] || '',
				scrub: scrubEffect,
				anticipatePin: true,
				invalidateOnRefresh: true,
				...(once ? {} : { toggleActions: 'play none none reverse' }),
				onUpdate: (scrollTrigger) => {
					// Função de callback para atualizações durante o scroll (opcional).
				},
			},
		})
		.fromTo(element, from, to)
		.timeScale(1)
	element.tl = tl
}

export function animateFromInit() {
	const els = findAll('[data-anima]')

	els.forEach((elem) => {
		animateFrom(elem)
	})
}
