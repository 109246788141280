import gsap from 'gsap'
import Swiper from 'swiper'
import { EffectCreative, EffectFade, Autoplay } from 'swiper/modules'
import { find } from 'utils/dom'
import { findAll, height } from '../utils/dom'

export default function () {
	const Page = find("[js-page='Clientes']")
	if (!Page) return { init: () => null }

	function animationLogo() {
		console.log('run clientes')
		gsap.utils.toArray('.column').forEach((column, index) => {
			const direction = index % 2 === 0 ? 1 : -1 // Alterna direção
			const distance = column.scrollHeight / 2 // Metade da altura da coluna
			const startY = direction === 1 ? '0%' : `-${distance}px` // Alterna o ponto de início
			const endY = direction === 1 ? `-${distance}px` : '0%' // Alterna o ponto final

			gsap.fromTo(
				column,
				{ y: startY },
				{
					y: endY,
					duration: 100,
					ease: 'linear',
					repeat: -1,
				}
			)
		})
	}

	function init() {
		animationLogo()
	}

	return {
		init,
	}
}
